import { DocumentState } from '@common/features/submission/types';
import { useDownloadAndOpenFile } from '@common/hooks/useDownloadAndOpenFile';
import { DocumentStatus, DocumentType } from '@common/types';
import { hasOriginalFilePublished, htmlFileIsAvailable, resolvePrimaryAndSecondaryFile } from '@features/document/helpers';
import { useFetchPublicProductGroupState } from '@features/productGroup/hooks/useFetchPublicProductGroupState';
import { useDownloadAndOpenPublishedFile } from '@hooks/useDownloadAndOpenPublishedFile';
import { useGetOriginalFileExtensionType } from '@hooks/useGetOriginalFileExtensionType';
import { useState } from 'react';
import DocumentDetailsContent from './DocumentDetailsContent';
import ReadonlyDocumentDetailsContent from './ReadonlyDocumentDetailsContent';
import RetiredContentDetails from './RetiredDocumentDetailsContent';
import { IContentDetailsParent } from './types';

export const DocumentDetailsParentContent = ({
  submission,
  submissionId,
  documentId,
  contentType,
  isFormExtended = true,
  documentDetailsReadonlySections,
  isEditable = false,
  isAdmin,
  documentProcessingDetails,
  onCancel,
  onSuccess,
}: IContentDetailsParent) => {
  const { downloadAndOpen } = useDownloadAndOpenFile();
  const { downloadAndOpen: downloadAndOpenPublishedDocument } = useDownloadAndOpenPublishedFile();
  const { getExtensionByDocumentType } = useGetOriginalFileExtensionType();

  const { primaryFile, secondaryFile } = resolvePrimaryAndSecondaryFile(contentType, submission, Number(documentId), documentProcessingDetails);

  const productGroupName = submission?.title;
  const contentTitle = primaryFile?.documentTitle ?? '';
  const originalFileName = primaryFile?.originalFileName ?? '';
  const secondaryOriginalFileName = secondaryFile?.originalFileName ?? '';

  const version = primaryFile?.version ?? 1;

  const { publicProductGroupState } = useFetchPublicProductGroupState(submission?.productGroupId?.toString(), false);

  const handleFileDownload = () => downloadAndOpen({ submissionId, documentId });
  const handleFileDownLoadPrimaryDocument = () =>
    downloadAndOpen({
      submissionId,
      documentId,
      contentType: primaryFile?.type,
      lastUpdated: downloadTimeStamp,
    });

  const [downloadTimeStamp] = useState<string>(Math.floor(Date.now() / 1000).toString());

  const handleFileDownLoadSecondaryDocument = () =>
    downloadAndOpen({
      submissionId,
      documentId,
      contentType: DocumentType.Pil,
      lastUpdated: downloadTimeStamp,
    });

  const handleDocumentDownload = () => {
    downloadAndOpenPublishedDocument({
      documentId,
      version: version.toString(),
      documentType: primaryFile?.type,
      extensionType: getExtensionByDocumentType(primaryFile?.type),
    });
  };

  const documentWasPublished = primaryFile?.status === DocumentStatus.Published || primaryFile?.status === DocumentStatus.Retired;
  const documentHasOriginalFilePublished = hasOriginalFilePublished(primaryFile);

  const getDocument = documentWasPublished
    ? documentHasOriginalFilePublished
      ? handleDocumentDownload
      : undefined
    : documentProcessingDetails?.documentProcessingFlow === 'qrdPil'
    ? handleFileDownLoadPrimaryDocument
    : handleFileDownload;

  const getSecondaryDocument = documentWasPublished
    ? documentHasOriginalFilePublished
      ? handleDocumentDownload
      : undefined
    : handleFileDownLoadSecondaryDocument;

  const displayHtmlView =
    !!primaryFile && !!publicProductGroupState && htmlFileIsAvailable(primaryFile, publicProductGroupState.documents);

  if (isEditable && primaryFile?.state !== DocumentState.Retired) {
    return (
      <DocumentDetailsContent
        onCancel={onCancel}
        isFormExtended={isFormExtended}
        documentReadonlySections={documentDetailsReadonlySections}
        productGroupName={productGroupName}
        submissionId={parseInt(submissionId)}
        documentId={documentId}
        url={primaryFile!.link || ''}
        contentType={contentType}
        contentTitle={contentTitle}
        originalFileName={originalFileName}
        version={version}
        onSuccess={onSuccess}
        onFileDownload={getDocument}
        onSecondaryFileDownload={getSecondaryDocument}
        showHistoryFiltering={false}
        documents={submission.documents}
        isAdmin={isAdmin}
        secondaryOriginalFileName={secondaryOriginalFileName}
        documentProcessingDetails={documentProcessingDetails}
      />
    );
  }

  if (primaryFile?.state === DocumentState.Retired) {
    return (
      <RetiredContentDetails
        document={primaryFile}
        submissionId={submissionId}
        documentId={documentId}
        contentType={contentType}
        contentTitle={contentTitle}
        originalFileName={originalFileName}
        onCancel={onCancel}
        onSuccess={onSuccess}
        onFileDownload={getDocument}
        displayHtmlViewTab={!!displayHtmlView}
        showHistoryFiltering={false}
      />
    );
  }

  return (
    <ReadonlyDocumentDetailsContent
      contentType={contentType}
      title={productGroupName}
      document={primaryFile!}
      onCancel={onCancel}
      onFileDownload={getDocument}
      onSecondaryFileDownload={getSecondaryDocument}
      displayHtmlViewTab={!!displayHtmlView}
      submissionId={submissionId}
      showHistoryFiltering={false}
      isAdmin={isAdmin}
      secondaryOriginalFileName={secondaryOriginalFileName}
      documentProcessingDetails={documentProcessingDetails}
    />
  );
};
