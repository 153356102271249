import { SubmissionStatus, SubmissionTaskStatuses } from '@common/services/types';
import {
  CustomerAllowedTaskActions,
  Dictionary,
  DocumentStatus,
  DocumentType,
  ISelectOption,
  SubmissionTaskType,
} from '@common/types';
import { ReactNode } from 'react';
import { IDocumentAttributes } from '../document/helpers';

export interface ISubmissionDto {
  submissionId: number;
  companyId: number;
  name: string;
  title: string;
  status: SubmissionStatus;
  productGroupId?: number | null;
  productGroup: IProductGroupDto;
  assumedProductGroupName?: string;
  productFamilyId?: number;
  productFamilyName?: string;
  products: IProductDto[];
  documents: IDocumentDto[];
  isEditable: boolean;
}

export interface ISubmissionStateDiffDto extends ISubmissionDto {
  productGroupChanges: IProductGroupChangesDto;
  productsChanges: IProductChangesDto[];
  assignmentChanges: IAssignmentChangesDto[];
}

export interface IAssignmentChangesDto {
  productId: number;
  documentId: number;
  documentType: DocumentType;
  changeType: ChangeType;
}

export interface IProductGroupChangesDto {
  productType: DiffDto<string>;
  maHolderCompanyId: DiffDto<number | null>;
  maHolderCompanyName: DiffDto<string | null>;
}

export interface IProductChangesDto {
  productId: number;
  productName: DiffDto<string>;
  blackTriangle: DiffDto<boolean | null>;
  legalCategories: Dictionary<ChangeType>;
  maNumbers: Dictionary<ChangeType>;
  atcCodes: Dictionary<ChangeType>;
  ampIds: Dictionary<ChangeType>;
  isSuitableForVegans: DiffDto<boolean | null>;
  isLactoseFree: DiffDto<boolean | null>;
  isGlutenFree: DiffDto<boolean | null>;
  isPregnancyRisk: DiffDto<boolean | null>;
  contactGroup: DiffDto<string | null>;
  smpcTitle: DiffDto<string | null>;
  pilTitle: DiffDto<string | null>;
}

export interface DiffDto<T> {
  value: T;
  changeType?: ChangeType;
}

export interface IProductGroupDto {
  productGroupName: string;
  productFamilyId: number;
  productFamilyName: string;
  productType: string;
  companyId: number;
  companyName: string;
  maHolderCompanyId?: number;
  maHolderCompanyName?: string;
  substances: ISubstanceDto[];
}

export enum ChangeType {
  Added = 'Added',
  Removed = 'Removed',
  Unchanged = 'Unchanged',
}

export interface ISubmissionTaskDto {
  submissionTaskId: number;
  type: SubmissionTaskType;
  submissionId: number;
  productGroupName: string;
  submittedAt: Date | null;
  submittedByUser: string;
  companyId: number;
  companyName: string;
  version: number;
  region: string;
  fastTrack: boolean;
  assignedToUser: string;
  status: SubmissionTaskStatuses;
  contentTitle: string;
  documentId: number;
  allowedCustomerActions: CustomerAllowedTaskActions[];
  isFileValid: boolean;
  isCustomerApprovalRequired: boolean | null;
  documentType: DocumentType | null;
  customerLink: string | undefined;
  daysInProgress: number | null;
}

export interface ISubmissionTasksDto {
  items: ISubmissionTaskDto[];
  totalCount: number;
}

export interface IActiveIngredient {
  id: string | null;
  name: string;
  denominatorUnit: string | null;
  denominatorValue: string | null;
  numeratorUnit: string | null;
  numeratorValue: string | null;
}

export interface ISubstanceDto {
  id: string | null;
  name: string;
}

export interface ISubmissionMetadataDto {
  maHolder: MaHolderData;
}

export interface MaHolderData {
  isSameAsSubmitterCompany: boolean;
  companyName: string;
}

export interface IProductDto {
  productId: number;
  name: string;
  isNameSetByUser: boolean;
  regulatorApprovedName: string;
  isRegulatorApprovedNameSetByUser: boolean;
  linkedSmpcTitle: string;
  linkedPilTitle: string;
  status: ProductStatus;
  lastChangedDate: Date;
  atcCodes: string[] | null;
  maNumbers: IMaNumber[] | null;
  ampIds: string[] | null;
  legalCategories: string[] | null;
  hasBlackTriangle: boolean | null;
  isSuitableForVegans: boolean | null;
  isLactoseFree: boolean | null;
  isGlutenFree: boolean | null;
  isPregnancyRisk: boolean | null;
  sla?: string;
  changeType: ProductChangeType;
  productForm: string | null;
  extraInformation: string | null;
  linkedDocumentIds: number[];
  activeIngredients: IActiveIngredient[];
  isDiscontinued: boolean;
  discontinuationReason: string | null;
  discontinuationReasonId: number | null;
  hasPublishedIngredientStrenghts: boolean;
  contactGroup: string;
  state: ProductState;
}

export interface IDocumentDto {
  documentTitle: string;
  version: number;
  details: string | null;
  documentId: number;
  originalFileName?: string;
  link: string | null;
  htmlContent: string | null;
  regulatorApprovalDate?: Date;
  approvedByRegulator: string;
  authorisedDate: Date | null;
  daysSinceApproval?: number;
  relatedProductCount?: number;
  submittedByUser?: string;
  submittedDate?: Date;
  status: DocumentStatus;
  type: DocumentType;
  linkedProductIds: number[];
  reasonsForSubmission: ISubmissionReasonDto[];
  state: DocumentState;
  reasonForRetirementId: number | null;
  reasonForRetirement: string | null;
  lastUpdateDate: Date;
  hasDigitalVersion: boolean | null; // calculated
  isVisibleToHCP: boolean | null;
  contentDescription: string | null;
}

export interface IDocumentWithAttributes {
  document?: IDocumentDto;
  attributes: IDocumentAttributes;
}

export interface IDocumentWithTask extends IDocumentDto {
  submissionTaskId: number | null;
  taskType: SubmissionTaskType | null;
  sla: string | null;
  customerLink: string | null;
  isConversionInProgress: boolean | null;
  isConversionFailed: boolean | null;
}

export interface IMaNumber {
  number: string;
  country: string;
}

export interface IContractGroupDto {
  code: string;
}

export interface ISubmissionReasonDto {
  id: number;
  text: string;
}

export interface IProductGroup {
  id: string;
  name: string;
  products: IProductGroupProduct[];
  substances: ISubstanceDto[];
  companyId: string;
}

export interface IProductGroupProduct {
  id: string;
  regulatorName: string;
  displayName: string;
  status: string;
}

export enum ProductStatus {
  Unknown = 'Unknown',
  Draft = 'Draft',
  Submitted = 'Submitted',
  Published = 'Published',
  Discontinued = 'Discontinued',
  SoftDeleted = 'SoftDeleted',
  Retired = 'Retired',
}

export enum ProductChangeType {
  Unchanged = 'Unchanged',
  New = 'New',
  Updated = 'Updated',
  Discontinued = 'Discontinued',
  Retirement = 'Retirement',
}

export enum ProductState {
  Unchanged = 'Unchanged',
  New = 'New',
  Updated = 'Updated',
  Discontinued = 'Discontinued',
  Retirement = 'Retirement',
}

export enum DocumentState {
  Unchanged = 'Unchanged',
  New = 'New',
  Updated = 'Updated',
  Retired = 'Retired',
  PendingDigitalVersion = 'PendingDigitalVersion',
}

export enum SubmissionTypes {
  UPDATE_PRODUCT = 'UPDATE_PRODUCT',
  CREATE_PRODUCT = 'CREATE_PRODUCT',
}

export interface INotesQueryDependencies {
  status: ISelectOption;
  fastTrack: ISelectOption;
  assignedUser: ISelectOption;
}

export { DocumentType };

export interface IActiveIngredientsDto {
  denominator: IProductDenominator | null;
  numerators: IProductNumerator[];
}

export interface IProductDenominator {
  value: number | null;
  unit: string;
}

export interface IProductNumerator extends IProductDenominator {
  name: string;
}

export interface ICompanySubmission {
  status: string;
  submissionId: number;
  submissionTitle: string;
  submittedAt: Date | null;
  submittedBy: string;
  expandableContent?: ReactNode;
  containsTasks: boolean;
  existingProductGroupId?: number;
  daysInProgress: number | null;
}

export interface ICompanySubmissionsDto {
  items: ICompanySubmission[];
  totalCount: number;
  totalPages: number;
  currentPage: number;
}

export interface IProductFamilyDto {
  id: string;
  name: string;
  productGroups: IProductGroup[];
  type: string;
  isDraft: boolean;
}

export interface UpdateSubmissionActiveIngredientsCommand {
  name: string;
  substances: ISubstanceDto[];
}

export interface ISubmissionTaskProps {
  taskId: string;
  submissionId: string;
  submissionTask?: ISubmissionTaskDto;
  submission?: ISubmissionDto;
  productGroupWithTaskApplied?: ISubmissionStateDiffDto;
  onDocumentAssociationSuccess?: () => void;
  onProductGroupUpdateSuccess?: () => void;
}
