import { Icons } from '@common/icons';
import { UnderlinedLink } from '../UnderlinedLink/styles';
import { MouseEventHandler } from 'react';
import { FailedWrapper } from './styles';

export interface IDocumentStatus {
  conversionState: { isFailed: boolean | null; isInProgress: boolean | null };
  onViewClick?: MouseEventHandler<HTMLAnchorElement>;
}

const ConversionStatus = ({ conversionState, onViewClick }: IDocumentStatus) => {
  const renderStatus = () => {
    if (conversionState?.isInProgress) {
      return <Icons.ConversionInProgrssTag />;
    }
    if (conversionState?.isFailed) {
      return (
        <FailedWrapper>
          <Icons.FailedConversionTag />
          <UnderlinedLink onClick={onViewClick} to="#">
            View
          </UnderlinedLink>
        </FailedWrapper>
      );
    }

    return <Icons.ValidConversionTag />;
  };
  return renderStatus();
};

export default ConversionStatus;

