import React, { useState } from 'react';

import { prepareTaskDocumentColumns } from './helpers';
import { TaskTypeToStringMap, getTaskIcon } from '@common/helpers';

import DatapharmTable from '@components/DatapharmTable/DatapharmTable';
import { SubmissionTaskXmlState } from './SubmissionTaskXmlState';

import { useFetchCompanyConfiguration } from '@common/features/configuration/useFetchCompanyConfiguration';

import { getDocumentAttributes } from '@common/features/document/helpers';
import { DocumentType, ErrorLog, TaskTypeToDocumentTypeMap } from '@common/types';
import { IDocumentDto, ISubmissionTaskDto } from '@common/features/submission/types';
import { ITaskDocumentTableCallbacks } from '@common/components/DatapharmTable/types';

import { SubmissionTaskDetailsUnitWrapper } from '../../styles';
import { StyledSVG, TableOverflowContainer } from '@common/styles';
import * as Styled from './styles';
import { useAppSelector } from '@common/hooks/redux';
import { selectDocumentConversionResults, selectToken } from '@common/features/user/selectors';
import { DocumentConversionErrorsModal } from '@common/components/DocumentConversionErrorsModal';
import { IDocumentConversionError, useFetchDocumentConversionErrors } from '@common/hooks/useFetchDocumentConversionErrors';
import { fetchDocumentConversionErrors } from '@common/services/documentService';

interface Props {
  submissionTask: ISubmissionTaskDto;
  documents: IDocumentDto[];
  isLoading: boolean;
  actionCallbacks: (documentType?: DocumentType) => ITaskDocumentTableCallbacks;
  onDetailsClick: (documentId: string) => void;
  onContentTitleClick: (documentId: string) => void;
  onContentDescriptionClick: (text: string) => void;
  onViewContent: (content: string, title: string) => void;
  isReadonly?: boolean;
  isAdmin: boolean;
}

export const TaskDocumentTable = ({
  submissionTask,
  documents,
  isLoading,
  actionCallbacks,
  onDetailsClick,
  onContentTitleClick,
  onContentDescriptionClick,
  onViewContent,
  isReadonly = false,
  isAdmin = false,
}: Props) => {
  const { companyConfiguration } = useFetchCompanyConfiguration(submissionTask.companyId);
  const contentType = TaskTypeToDocumentTypeMap[submissionTask.type];

  const documentConversionResults = useAppSelector(selectDocumentConversionResults);
  const token = useAppSelector(selectToken);
  const documentAttributes = getDocumentAttributes(contentType!, isAdmin);

  const [conversionErrors, setConversionErrors] = useState<ErrorLog[]>(Array<ErrorLog>());
  const [isDocumentConversionErrorsModalOpen, setIsDocumentConversionErrorsModalOpen] = useState(false);

  const openDocumentConversionErrorModal = async (taskId: number) => {
    const conversionErrors = (await fetchDocumentConversionErrors(token, taskId!)) as IDocumentConversionError[];
    await setConversionErrors(conversionErrors?.map((e) => ({ mainError: e.errorType, subError: e.message })));
    await setIsDocumentConversionErrorsModalOpen(true);
  };

  const closeDocumentConversionErrorModal = () => {
    setIsDocumentConversionErrorsModalOpen(false);
  };

  const columns = prepareTaskDocumentColumns(
    actionCallbacks,
    onContentTitleClick,
    onDetailsClick,
    onContentDescriptionClick!,
    onViewContent,
    documentAttributes,
    isReadonly,
    submissionTask.isFileValid,
    submissionTask.submissionTaskId,
    openDocumentConversionErrorModal,
    documentConversionResults.find((dcr) => dcr.taskId === submissionTask.submissionTaskId),
    companyConfiguration,
  );

  return (
    <SubmissionTaskDetailsUnitWrapper>
      <Styled.HeaderWrapper>
        <Styled.HeaderTextWrapper>{TaskTypeToStringMap[submissionTask.type]}</Styled.HeaderTextWrapper>
        <StyledSVG height={25} src={getTaskIcon(submissionTask.type)} />
        <SubmissionTaskXmlState submissionType={submissionTask.type} isFileValid={submissionTask.isFileValid} />
      </Styled.HeaderWrapper>
      {!isLoading && (
        <Styled.TableWrapper>
          <TableOverflowContainer>
            <DatapharmTable columns={columns} documents={documents} data-testid="task-document-table" />
          </TableOverflowContainer>
        </Styled.TableWrapper>
      )}
      <DocumentConversionErrorsModal
        isOpen={isDocumentConversionErrorsModalOpen}
        contentType={contentType!}
        documentConversionErrors={conversionErrors}
        showLoader={false}
        onCloseClick={closeDocumentConversionErrorModal}
      />
    </SubmissionTaskDetailsUnitWrapper>
  );
};
