import styled from 'styled-components';
import { Tabs } from 'react-tabs';
import { ReactSVG } from 'react-svg';

import { StyledSVG } from '@common/styles';
import { StyledLink } from '@common/components/Link/styles';

export const FullPageTabs = styled(Tabs)`
  display: flex;
  flex-direction: column;
  width: 95%;
  height: calc(100vh - 207px);
  margin: 0 auto;
  background-color: ${({ theme }) => theme.colors.superlightGrey};
  border-left: 1px solid ${({ theme }) => theme.colors.borderGray};
  border-right: 1px solid ${({ theme }) => theme.colors.borderGray};
`;

export const Section = styled.div`
  width: 100%;
  height: 100%;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
`;

export const HorizontalDivider = styled.div`
  width: '100%';
  margin: 0;
  border-bottom: 1px solid ${({ theme }) => theme.elements.borderGrey};
`;

export const SectionHeader = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  column-gap: 1rem;
  padding: 10px 20px;
  height: 70px;

  ${StyledLink} {
    text-decoration: underline;
    font-weight: 400;
    color: ${({ theme }) => theme.elements.linkBlue};
  }
`;

export const Icon = styled(StyledSVG)`
  height: 2rem;
  width: 2rem;

  svg {
    height: 2rem;
    width: 2rem;
  }
`;

export const ExpandSVG = styled(ReactSVG)`
  div {
    display: block;
    margin-right: 0px;
    svg {
      vertical-align: middle;
    }
  }
`;

export const FullPagePopupBackground = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.white};
`;

export const ExpandAllButton = styled.button`
  margin-bottom: 10px;
  margin-left: auto;
  padding-right: 30px;
  appearance: none;
  border: none;
  display: flex;
  align-items: center;
  background-color: transparent;
  color: #142b61;
  font-size: 16px;
  text-decoration: none;
  transition: 0.35s;
  font-weight: 400;

  :hover {
    color: #71c48c;
    cursor: pointer;

    .expand-icon {
      transition: 0.35s;
      filter: invert(76%) sepia(22%) saturate(702%) hue-rotate(87deg) brightness(120%) contrast(86%);
    }
  }

  .expand-icon {
    margin-left: 20px;
    > div {
      margin: 0;
    }
  }

  .expand-icon.open {
    transform: rotate(180deg);
  }

  .expand-icon.closed {
    transform: rotate(0deg);
  }
`;

export const Html = styled.div`
  background-color: ${({ theme }) => theme.colors.defaultWhite};
  overflow: auto;
  width: 100%;
  height: 100%;
  padding: 10px;
  border: 1px solid ${({ theme }) => theme.colors.borderGray};

  .spcWrapper,
  .xpilcontent {
    color: #161616 !important;
    line-height: 1.4;

    *:not(table) {
      font-family: Montserrat, 'Source Sans Pro', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    }

    table {
      font-family: Arial, Helvetica, sans-serif;

      * {
        font-family: Arial, Helvetica, sans-serif;
      }
    }

    details:not(table) {
      font-size: 14px;
    }

    .address {
      margin-bottom: 10px;
    }

    :not(details) {
      padding-left: 20px;
      padding-right: 20px;
    }

    details > summary {
      margin: 0;
      color: #606060;
    }

    summary {
      margin: 0;
      font-size: 16px;
      scroll-margin-top: 50px;
    }

    summary > div {
      margin: 0;
      font-size: 16px;
      scroll-margin-top: 30px;
    }

    summary:hover,
    summary > div:hover {
      cursor: pointer;
      padding-right: 0;
    }

    summary::marker,
    summary > div::marker {
      content: '';
    }

    summary::after {
      content: url('/icons/expand_tick.svg');
      position: absolute;
      right: 6px;
      top: 0px;
    }

    details[open] summary:after {
      content: url('/icons/collapse_tick.svg');
      position: absolute;
      right: 6px;
      top: 0px;
    }
    details:last-of-type {
      margin-bottom: 20px;
    }

    .smpc-subtitle {
      font-weight: bold;
    }

    .paragraph > * {
      padding: 0 50px;
    }

    .paragraph > .xBullet {
      padding: 0 70px;
      margin-bottom: 10px;
    }

    .paragraph .sectionWrapper > p > ul {
      text-decoration: none;
      font-size: 14px;
      font-weight: 600;
    }

    .paragraph summary {
      padding: 20px;
      padding-right: 40px;
      font-weight: 600;
      position: relative;
    }

    .paragraph summary:hover {
      background-color: rgba(113, 196, 140, 0.1);
    }

    .paragraph details > * {
      padding: 0;
    }

    .paragraph details > .xBullet {
      padding: 0 20px;
      margin-bottom: 10px;
    }

    .paragraph details > summary {
      padding: 20px 0;
      font-size: 16px;
      color: #71c48c;
    }

    .paragraph details > summary:hover {
      text-decoration: underline;
      background-color: unset;
    }

    .paragraph details > summary::after {
      content: '';
    }

    .paragraph details[open] > summary:after {
      content: '';
    }

    caption {
      padding-top: 8px;
      padding-bottom: 8px;
      color: #ededed;
      text-align: left;
    }

    .table-responsive,
    .emc-table-responsive {
      overflow-x: auto;
      min-height: 0.01%;
    }

    table {
      background-color: transparent;
      margin-bottom: 10px;
      font-size: 13px;
      border: 1px solid;
      border-collapse: collapse;
      border-spacing: 0;
    }

    table,
    tr,
    td {
      font-size: 14px;
      border-color: #606060;

      p {
        height: fit-content;
        margin: 0;
        padding: 4px 0;
      }
    }

    table tr,
    table td {
      font-size: 13px;
      border: 1px solid;
    }

    table[border='0'] {
      border: none;

      tr,
      td {
        border: none;
      }
    }

    td,
    th {
      padding: 2px;
    }

    th {
      text-align: left;
    }

    p {
      margin: 0 0 10px;
    }

    .table {
      width: 100%;
      max-width: 100%;
      margin-bottom: 20px;
    }

    .table > thead > tr > th,
    .table > tbody > tr > th,
    .table > tfoot > tr > th,
    .table > thead > tr > td,
    .table > tbody > tr > td,
    .table > tfoot > tr > td {
      padding: 0;
      line-height: 1.4;
      vertical-align: top;
      border-top: none;
    }

    .table > thead > tr > th {
      vertical-align: bottom;
      border-bottom: 2px solid #ddd;
    }

    .table > caption + thead > tr:first-child > th,
    .table > colgroup + thead > tr:first-child > th,
    .table > thead:first-child > tr:first-child > th,
    .table > caption + thead > tr:first-child > td,
    .table > colgroup + thead > tr:first-child > td,
    .table > thead:first-child > tr:first-child > td {
      border-top: 0;
    }

    .table > tbody + tbody {
      border-top: 2px solid #ddd;
    }

    .table .table {
      background-color: #f9f9f9;
    }

    .table-condensed > thead > tr > th,
    .table-condensed > tbody > tr > th,
    .table-condensed > tfoot > tr > th,
    .table-condensed > thead > tr > td,
    .table-condensed > tbody > tr > td,
    .table-condensed > tfoot > tr > td {
      padding: 5px;
    }

    .table-bordered {
      border: 1px solid #ddd;
    }

    .table-bordered > thead > tr > th,
    .table-bordered > tbody > tr > th,
    .table-bordered > tfoot > tr > th,
    .table-bordered > thead > tr > td,
    .table-bordered > tbody > tr > td,
    .table-bordered > tfoot > tr > td {
      border: 1px solid #ddd;
    }

    .table-bordered > thead > tr > th,
    .table-bordered > thead > tr > td {
      border-bottom-width: 2px;
    }

    .table-striped > tbody > tr:nth-of-type(odd) {
      background-color: #f9f9f9;
    }

    .table-hover > tbody > tr:hover {
      background-color: #f5f5f5;
    }

    table col[class*='col-'] {
      position: static;
      float: none;
      display: table-column;
    }

    table td[class*='col-'],
    table th[class*='col-'] {
      position: static;
      float: none;
      display: table-cell;
    }

    .table > thead > tr > td.active,
    .table > tbody > tr > td.active,
    .table > tfoot > tr > td.active,
    .table > thead > tr > th.active,
    .table > tbody > tr > th.active,
    .table > tfoot > tr > th.active,
    .table > thead > tr.active > td,
    .table > tbody > tr.active > td,
    .table > tfoot > tr.active > td,
    .table > thead > tr.active > th,
    .table > tbody > tr.active > th,
    .table > tfoot > tr.active > th {
      background-color: #f5f5f5;
    }

    .table > thead > tr > td.success,
    .table > tbody > tr > td.success,
    .table > tfoot > tr > td.success,
    .table > thead > tr > th.success,
    .table > tbody > tr > th.success,
    .table > tfoot > tr > th.success,
    .table > thead > tr.success > td,
    .table > tbody > tr.success > td,
    .table > tfoot > tr.success > td,
    .table > thead > tr.success > th,
    .table > tbody > tr.success > th,
    .table > tfoot > tr.success > th {
      background-color: #dff0d8;
    }

    .table > thead > tr > td.info,
    .table > tbody > tr > td.info,
    .table > tfoot > tr > td.info,
    .table > thead > tr > th.info,
    .table > tbody > tr > th.info,
    .table > tfoot > tr > th.info,
    .table > thead > tr.info > td,
    .table > tbody > tr.info > td,
    .table > tfoot > tr.info > td,
    .table > thead > tr.info > th,
    .table > tbody > tr.info > th,
    .table > tfoot > tr.info > th {
      background-color: #d9edf7;
    }

    .table > thead > tr > td.warning,
    .table > tbody > tr > td.warning,
    .table > tfoot > tr > td.warning,
    .table > thead > tr > th.warning,
    .table > tbody > tr > th.warning,
    .table > tfoot > tr > th.warning,
    .table > thead > tr.warning > td,
    .table > tbody > tr.warning > td,
    .table > tfoot > tr.warning > td,
    .table > thead > tr.warning > th,
    .table > tbody > tr.warning > th,
    .table > tfoot > tr.warning > th {
      background-color: #fcf8e3;
    }

    .table > thead > tr > td.danger,
    .table > tbody > tr > td.danger,
    .table > tfoot > tr > td.danger,
    .table > thead > tr > th.danger,
    .table > tbody > tr > th.danger,
    .table > tfoot > tr > th.danger,
    .table > thead > tr.danger > td,
    .table > tbody > tr.danger > td,
    .table > tfoot > tr.danger > td,
    .table > thead > tr.danger > th,
    .table > tbody > tr.danger > th,
    .table > tfoot > tr.danger > th {
      background-color: #f2dede;
    }

    .table-hover > tbody > tr > td.active:hover,
    .table-hover > tbody > tr > th.active:hover,
    .table-hover > tbody > tr.active:hover > td,
    .table-hover > tbody > tr:hover > .active,
    .table-hover > tbody > tr.active:hover > th {
      background-color: #e8e8e8;
    }

    .table-hover > tbody > tr > td.success:hover,
    .table-hover > tbody > tr > th.success:hover,
    .table-hover > tbody > tr.success:hover > td,
    .table-hover > tbody > tr:hover > .success,
    .table-hover > tbody > tr.success:hover > th {
      background-color: #d0e9c6;
    }

    .table-hover > tbody > tr > td.info:hover,
    .table-hover > tbody > tr > th.info:hover,
    .table-hover > tbody > tr.info:hover > td,
    .table-hover > tbody > tr:hover > .info,
    .table-hover > tbody > tr.info:hover > th {
      background-color: #c4e3f3;
    }

    .table-hover > tbody > tr > td.warning:hover,
    .table-hover > tbody > tr > th.warning:hover,
    .table-hover > tbody > tr.warning:hover > td,
    .table-hover > tbody > tr:hover > .warning,
    .table-hover > tbody > tr.warning:hover > th {
      background-color: #faf2cc;
    }

    .table-hover > tbody > tr > td.danger:hover,
    .table-hover > tbody > tr > th.danger:hover,
    .table-hover > tbody > tr.danger:hover > td,
    .table-hover > tbody > tr:hover > .danger,
    .table-hover > tbody > tr.danger:hover > th {
      background-color: #ebcccc;
    }
  }
`;

export const SectionBody = styled.div`
  padding: 10px 20px;
  height: calc(100vh - 18rem);
`;
