import { DocumentExtensionType, DocumentType } from '@common/types';
import { useFetchPublishedDocument } from '@hooks/useFetchPublishedDocument';
import * as Styled from './styles';
import { Html } from '@common/pages/ViewHtml/Html';
import React from 'react';
import { PleaseWaitLoader } from '@components/PleaseWaitLoader';
import styled from 'styled-components';

export const LoaderWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: inherit;
`;

export const HtmlDocument = ({
  documentId,
  version,
  documentType,
}: {
  documentId: string;
  version: string;
  documentType?: DocumentType;
}) => {
  const documentTypeForHtml = documentType === DocumentType.Pil ? DocumentType.Epil : documentType;
  const documentTypeForHtmlView = documentType === DocumentType.Smpc ? DocumentType.Smpc : DocumentType.Pil;
  const { data, documentFetching } = useFetchPublishedDocument(
    false,
    documentId,
    version,
    documentTypeForHtml,
    DocumentExtensionType.Html,
  );

  return (
    <Styled.SectionBody>
      {documentFetching ? (
        <LoaderWrap>
          <PleaseWaitLoader />
        </LoaderWrap>
      ) : (
          <Html html={data} docType={documentTypeForHtmlView} />
      )}
    </Styled.SectionBody>
  );
};
