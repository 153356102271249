import styled from 'styled-components';

export const FailedWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;

  a {
    margin-right: 5px;
    font-size: ${({ theme }) => theme.fontSizes.tiny};
  }
`;