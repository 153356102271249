import { ICheckboxEntry } from '@common/types';

export enum FileRoles {
  Original = 'original',
  Xml = 'xml',
  Html = 'html',
  ImageConversion = 'image-conversion',
  ImageUser = 'image-user',
  ErrorsXml = 'errors-xml',
  MigrationUnknown = 'migration-unknown',
  AlternativeText = 'alternative-text',
  PrimaryDocFile = 'primary-docfile',
  SecondaryPDFFile = 'secondary-pdffile',
}

export type AddModalOpenModes = 'Add' | 'Update' | null;

export interface IAddDocumentForm {
  documentId: number;
  documentTitle: string;
  file?: File;
  webLink?: string;
  hasDigitalVersion?: boolean;
  isCustomerApprovalRequired: boolean | null;
  includeSecondaryDocument: boolean | undefined;
  productIds: ICheckboxEntry[];
  content?: string;
  secondaryfile?: File;
  noAudioVideoUrl?: boolean;
}

export enum FormFields {
  documentId = 'documentId',
  documentTitle = 'documentTitle',
  file = 'file',
  webLink = 'webLink',
  hasDigitalVersion = 'hasDigitalVersion',
  isCustomerApprovalRequired = 'isCustomerApprovalRequired',
  productIds = 'productIds',
  content = 'content',
  secondaryfile = 'secondaryfile',
  includeSecondaryDocument = 'includeSecondaryDocument',
  noAudioVideoUrl = 'noAudioVideoUrl',
}

export enum ConversionErrorType {
  UnknownProblem = 'UnknownProblem',
  CorruptedFile = 'CorruptedFile',
  NestedTable = 'NestedTable',
  GridlessTable = 'GridlessTable',
}

export const conversionErrorTypeToCustomLabelMap: ConversionErrorTypToCustomLabel = {
  [ConversionErrorType.UnknownProblem]: 'We found an unknown problem',
  [ConversionErrorType.CorruptedFile]: 'Your file is corrupted',
  [ConversionErrorType.NestedTable]: 'Your document contains nested table',
  [ConversionErrorType.GridlessTable]: 'Your document contains gridless table',
};

type ConversionErrorTypToCustomLabel = {
  [key in string]?: string;
};

export enum RequestEpilStandardFormFields {
  isWithAlternativeText = 'isWithAlternativeText',
  alternativeTextFile = 'alternativeTextFile',
  alternativeTextFileName = 'alternativeTextFileName'
};
