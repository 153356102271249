import { IProductGroupChangesDto, IProductGroupDto, ISubmissionTaskDto } from '@common/features/submission/types';
import { getProductTypeLabel, formatDateTime } from '@common/helpers';
import { ProductGroupTableColumnKeys } from './constants';
import { CellWithChanges } from '@common/components/CellWithChanges/CellWithChanges';
import { getActions } from '../../helpers';
import { ActionType } from '@common/types';
import { IDocumentAttributes } from '@common/features/document/helpers';

export const getProductGroupTableColumnsData = (
  changes?: IProductGroupChangesDto,
  isReadonly?: boolean,
  onUpdateClick: () => void = () => {},
) =>
  [
    { accessor: ProductGroupTableColumnKeys.productGroupName, columnName: 'Product group' },
    {
      accessor: ProductGroupTableColumnKeys.maHolder,
      columnName: 'MA Holder',
      cell: ({ getValue }: any) => <CellWithChanges value={getValue()} changeType={changes?.maHolderCompanyId.changeType} />,
    },
    {
      accessor: ProductGroupTableColumnKeys.mahName,
      columnName: 'MAH name',
      cell: ({ getValue }: any) => <CellWithChanges value={getValue()} changeType={changes?.maHolderCompanyName.changeType} />,
    },
    {
      accessor: ProductGroupTableColumnKeys.companyName,
      columnName: 'Company name',
      cell: ({ getValue }: any) => <>{getValue()}</>,
    },
    {
      accessor: ProductGroupTableColumnKeys.productType,
      columnName: 'Product Type',
      cell: ({ getValue }: any) => <CellWithChanges value={getValue()} changeType={changes?.productType.changeType} />,
    },
    { accessor: ProductGroupTableColumnKeys.submittedBy, columnName: 'Submitted by' },
    { accessor: ProductGroupTableColumnKeys.dateSubmitted, columnName: 'Date submitted' },
    !isReadonly
      ? {
          accessor: ProductGroupTableColumnKeys.actions,
          columnName: 'Actions',
          cell: (info: any) => {
            const handleUpdate = () => onUpdateClick();
            return getActions(
              info.getValue(),
              '',
              () => {},
              () => {},
              handleUpdate,
              () => {},
              'productGroupTable',
            );
          },
        }
      : undefined,
  ].filter((x) => x);

export const mapToProductGroupData = (
  submissionTask: ISubmissionTaskDto | undefined,
  productGroup: IProductGroupDto | undefined,
  productGroupChanges?: IProductGroupChangesDto,
  documentAttributes?: IDocumentAttributes,
) => {
  if (!submissionTask) {
    return [];
  }

  const maHolderCompanyId = productGroupChanges?.maHolderCompanyId.value ?? productGroup?.maHolderCompanyId;
  const companyName = productGroupChanges?.maHolderCompanyName.value ?? productGroup?.maHolderCompanyName;
  const productType = productGroupChanges?.productType.value ?? productGroup?.productType;

  const isMaHolderSameAsCompany = productGroup && productGroup.companyId === maHolderCompanyId;

  return [
    {
      [ProductGroupTableColumnKeys.productGroupName]: `${productGroup?.productGroupName} ${formatActiveIngredients(
        productGroup,
      )}`,
      [ProductGroupTableColumnKeys.maHolder]: isMaHolderSameAsCompany ? 'Yes' : 'No',
      [ProductGroupTableColumnKeys.mahName]: isMaHolderSameAsCompany ? '-' : companyName,
      [ProductGroupTableColumnKeys.companyName]: productGroup?.companyName,
      [ProductGroupTableColumnKeys.productType]: getProductTypeLabel(productType),
      [ProductGroupTableColumnKeys.submittedBy]: submissionTask.submittedByUser,
      [ProductGroupTableColumnKeys.dateSubmitted]: submissionTask.submittedAt ? formatDateTime(new Date(submissionTask.submittedAt)) : null,
      [ProductGroupTableColumnKeys.actions]: documentAttributes?.shouldHandleProductGroupUpdate ? [ActionType.Update] : [],
    },
  ];
};

const formatActiveIngredients = (productGroup: IProductGroupDto | undefined) => {
  const activeIngredients = productGroup?.substances.reduce((result, current, index) => {
    if (index === productGroup?.substances?.length - 1) {
      return result + `${current.name}`;
    }

    return result + `${current.name}, `;
  }, '');

  return `(${activeIngredients})`;
};
