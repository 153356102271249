import { selectToken } from '@common/features/user/selectors';
import { useAppSelector } from '@common/hooks/redux';
import useWaitingMutation from '@common/hooks/useWaitingMutation';
import { requestEpilStandard } from '@common/services/documentService';

interface IRequestEpilStandardData {
  documentId: string;
  submissionId: string;
  alternativeTextFileName?: string;
}

export const useRequestEpilStandard = () => {
  const token = useAppSelector(selectToken);

  const { mutate } = useWaitingMutation(({ documentId, submissionId, alternativeTextFileName }: IRequestEpilStandardData) => requestEpilStandard(token, submissionId, documentId, {alternativeTextFileName}));

  return { requestEpil: mutate };
};
